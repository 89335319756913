<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md5 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Stocks
			</v-flex>
			<v-flex md7 class="font-level-3-bold my-auto text-right">
				<v-btn color="blue darken-4 white--text" class="mr-2" depressed tile>Upload</v-btn>
				<v-btn color="blue darken-4 white--text" depressed tile>Update</v-btn>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="10%" class="p-2 light-blue-bg">Stock #</th>
						<th width="10%" class="p-2 light-blue-bg">Barcode</th>
						<th class="p-2 light-blue-bg">Serial Number</th>
						<th class="p-2 light-blue-bg">Password</th>
						<th width="15%" class="p-2 light-blue-bg">Status</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td width="10%" class="p-2 border-top-light-grey"></td>
						<td width="10%" class="p-2 border-top-light-grey"></td>
						<td class="p-2 border-top-light-grey"></td>
						<td class="p-2 border-top-light-grey"></td>
						<th width="15%" class="p-2 border-top-light-grey">Status</th>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="stock-dialog">
			<Dialog :dialog="stockupdateDialog" :dialog-width="dialogWidth">
				<template v-slot:title>Confirm Cancel Reservation</template>
				<template v-slot:body>
					<p class="m-0 font-level-3">Are you sure you want to cancel the Reservation?</p>
				</template>
				<template v-slot:action>
					<v-btn :disabled="cancelLoading" class="mr-2" v-on:click="cancelDialog = false" depressed tile
						>Cancel</v-btn
					>
					<v-btn color="blue darken-4 text-white" depressed tile v-on:click="cancelReservation()"
						>Confirm</v-btn
					>
				</template>
			</Dialog>
		</div>
	</div>
</template>

<script>
import { toSafeInteger } from "lodash";
import Dialog from "@/view/components/Dialog";

export default {
	data() {
		return {
			stockupdateDialog: false,
		};
	},
	components: {
		Dialog,
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
